.pccHeadContentContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.pccHeadContentContainer > h1 {
  text-align: center;
  color: var(--customColor2);
  font-size: 30px;
}
.pccHeadContentContainer > h2 > span {
  color: var(--customColor1);
}
.pccHeadContentContainer > h2 {
    text-align: center;
    color: var(--customColor2);
    font-size: 30px;
  }
  .pccHeadContentContainer > h1 > span {
    color: var(--customColor1);
  }
.pccHeadContentContainer > p {
  text-align: justify;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .pccHeadContentContainer {
    /* border: solid red; */
    padding: 20px;
  }
}
