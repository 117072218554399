.mofaDocServiceContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.mofaDocServiceContainer > h2 {
  color: var(--customColor2);
  font-size: 30px;
}
.mofaDocServiceContainer > h2 > span {
  color: var(--customColor1);
}
.mofaDocServiceContainer > p {
  font-size: 20px;
  text-align: justify;
}
.docServCardContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.docServCardContainer > h3 {
  font-size: 20px;
  color: var(--customColor2);
  font-weight: bold;
}
.docServCardContainer > h3 > span {
  color: var(--customColor1);
}
.docServCardContainer > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .mofaDocServiceContainer {
    /* border: solid red; */
    padding: 20px;
  }
}
