.AttestationPageContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

