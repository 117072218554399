.hrdTimeContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 20px;
}
.hrdTimeContainer > h2 {
  width: 100%;
  text-align: start;
  font-size: 25px;
  color: var(--customColor1);
}
.hrdTimeContainer > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width:768px) {
  .hrdTimeContainer > h2 {
    width: 100%;
    text-align: center;
    font-size: 25px;
    color: var(--customColor1);
  }
}