.contactContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.contactHeadContainer {
  /* border: solid blue; */
  width: 100%;
  position: relative;
}
.contactHeadContainer > h2 {
  font-size: 30px;
  text-align: center;
  color: var(--customColor2);
}
.contactHeadContainer > h2 > span {
  color: var(--customColor1);
}

.contactHeadImg > img {
  width: 100%;
  height: 100%;
}
.contactMainContainer {
  /* border: solid brown; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.contactMainContent {
  /* border: solid blue; */
  width: 50%;
  position: relative;
}
.contactMainContent > img {
  width: 100%;
  height: 100%;
}
.contactUsCover {
  backdrop-filter: blur(1px);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.contactUsCardContainer {
  /* border: solid red; */
  width: 100%;
  height: 200px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}
.contactWrapper {
  /* border: solid brown; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
#contactUsCard {
  /* border: solid blue; */
  width: 100%;
}
#contactUsCard > p {
  /* border: solid blue; */
  width: 100%;
}
#contactUsCard > p > span {
  /* border: solid blue; */
  /* width: 100%; */
  color: var(--customColor1);
  font-weight: bold;
}
#contactUsCard > p {
  color: var(--customColor2);
}
#contactUsCard > p > a {
  color: var(--customColor2);
}
.contactUsCardAdd > p {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contactUsCardImg {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}
.contactUsCardImg > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.contactUsCardEmail > p {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contactUsCardEmail > p > a {
  color: black;
  text-decoration: none;
}
.contactUsCardPhone > p > a {
  color: black;
  text-decoration: none;
}
.contactUsCardEmail > p > a:hover {
  text-decoration: underline;
}

.contactUsCardPhone > p {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contactUsCardPhone > p > a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .contactContainer {
    /* border: solid red; */
    padding: 5px;
  }
  .contactMainContainer {
    /* border: solid brown; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contactMainContent {
    /* border: solid blue; */
    width: 100%;
    position: relative;
  }
  .contactUsCover {
    /* border: solid brown; */
    position: static;
    top: 0;
    width: 100%;
    height: 100%;
  }
  #contactUsCard {
    /* border: solid blue; */
    width: 100%;
  }
  .contactMainContent > img {
    width: 100%;
    height: 100%;
    display: none;
  }
  .contactUsCardContainer {
    /* border: solid red; */
    max-width: 300px;
    height: 100%;
    flex-direction: column;
    box-shadow: 0 0 10px black;
  }
  .contactWrapper {
    /* border: solid brown; */
    width: 100%;
    height: 100%;
  }
  .contactUsCardImg {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .contactUsCardAdd > p {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .contactUsCardContainer {
    /* border: solid red; */
    width: 100%;
    height: 250px;
  }
}
