.mofaTimeContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.mofaTimeContainer > h3 {
  width: 100%;
  font-size: 25px;
  color: var(--customColor1);
  text-align: start;
}
.mofaTimeContainer > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .mofaTimeContainer > h3 {
    font-size: 25px;
    color: var(--customColor1);
    text-align: center;
  }
}
