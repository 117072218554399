.mofaMainContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.mofaCardContainer {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--customWhite);
}
.mofaCardImg {
  border-radius: 10px;
  overflow: hidden;
}
.mofaCardImg > img {
  border-radius: 10px;
  transition: 300ms ease-in-out;
}
.mofaCardContainer:hover .mofaCardImg > img {
  transform: scale(1.1);
}
#mofaCard1Container {
  flex-direction: row;
}
#mofaCard2Container {
  flex-direction: row-reverse;
}
.mofaCardContent {
  /* border: solid brown; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
}
.mofaCardContent > h3 {
  text-align: start;
  width: 100%;
  color: var(--customColor2);
  font-size: 25px;
}
.mofaCardContent > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  #mofaCard1Container {
    flex-direction: column;
  }
  #mofaCard2Container {
    flex-direction: column;
  }
  .mofaCardImg > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 300ms ease-in-out;
  }
  .mofaCardContent > h3 {
    text-align: center;

  }
}
