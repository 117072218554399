.seoContentContainer {
  /* border: solid red; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.seoContentHeadContainer > h1 {
  /* border: solid; */
  font-size: 40px;
  font-weight: lighter;
  color: var(--customColor1);
  text-align: center;
}
.seoContentMainContainer {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.seoContentMainContent {
  /* border: solid blue; */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(236, 236, 236);
  padding: 20px;
  border-radius: 20px;
}

.seoContentMainContent > p {
  font-size: 20px;
  text-align: justify;
}
.seoContentMainContent > p > span {
  color: var(--customColor1);
}
.seoContent2Container {
  background-color: rgb(236, 236, 236);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
}
.seoContent2Content {
  background-color: white;
  border-radius: 10px;
}
.seoContent2Content > ul > li {
  list-style: none;
  margin: 20px;
  font-size: 15px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .seoContentContainer {
    /* border: solid red; */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .seoContent2Container {
    background-color: rgb(236, 236, 236);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }
  .seoContent2Content {
    background-color: white;
    border-radius: 10px;
    width: 100%;
  }
}
