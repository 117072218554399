.ourServicesContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}
.ourServiceHeadContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.underline {
  border: 2px solid var(--customColor1);
  width: 50%;
}

.ourServiceHeadContainer > h2 {
  color: var(--customColor2);
  font-size: 30px;
}
.ourServicesMainContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ourServicesCardContainer {
  /* border: solid green; */
  width: 20%;
  height: 400px;
  margin: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(163, 163, 163);
  overflow: hidden;
}
.ourServicesCardContainer > a {
  /* border: solid; */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: black;
}
.ourServicesCardCover {
  /* border: solid red; */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-100%);
  bottom: 0;
  transition: 200ms ease-in-out;
  background-color: #2a2a2aac;
  opacity: 0;
  z-index: 1;
}

.ourServicesCardContainer:hover .ourServicesCardCover {
  transform: translateY(0%);
  opacity: 1;
}
.ourServicesCardImg {
  /* border: solid blue; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ourServicesCardImg > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 400ms ease-in-out;
}
.ourServicesCardHead {
  /* border: solid brown; */
  width: 80%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(8, 0, 227);
  bottom: 50px;
  left: 0;
  position: absolute;
  background-color: var(--customColor1);
  transition: 400ms ease-in-out;
  overflow: hidden;
}
.ourServicesCardHead > h4 {
  /* border: solid red; */
  font-size: 40px;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #e96d02;
}
.ourServicesCardHead > h3 {
  position: absolute;
  z-index: 1;
  color: white;
  font-size: 20px;
}
.ourServicesCardPara {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}
.ourServicesCardContainer:hover .ourServicesCardHead {
  color: var(--customColor2);
}
.ourServicesCardContainer:hover .ourServicesCardImg > img {
  transform: scale(1.2);
}
.ourServicesCardPara > p {
  font-size: 20px;
  line-height: 25px;
  text-align: justify;
  transition: 100ms ease-in-out;
}
.ourServicesCardContainer:hover .ourServicesCardPara > p {
  color: white;
}
.cardIcon {
  color: black;
  font-size: 20px;
  transition: 100ms ease-in-out;
}
.ourServicesCardContainer:hover .cardIcon {
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .ourServicesCardContainer {
    /* border: solid green; */
    min-width: 300px;
    height: 400px;
    margin: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .ourServicesCardContainer {
    /* border: solid green; */
    min-width: 300px;
    height: 400px;
    margin: 20px;
  }
}
