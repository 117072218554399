.reviewsContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewsContent {
  /* border: solid blue; */
  width: 100%;
  height: 300px;
  overflow: hidden;
  padding: 10px;
  margin-top: -50px;
}
.reviewsHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.reviewsHeading > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
}
.reviewUnderline {
  border: 2px solid var(--customColor1);
  width: 10%;
}
