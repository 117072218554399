.upperNavContainer {
  /* border: solid greenyellow; */
  height: 7vh;
  width: 100%;
  background-color: var(--customColor1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.upperNavContainer > ul {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
}
.upperLogo {
  /* border: solid green; */
  height: 80%;
  width: 20%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}
.upperLogo > img {
  height: 90%;
}
.upperNavContainer > ul > li {
  /* border: solid; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px;
}
.upperNavContainer > ul > li > a {
  /* border: solid rebeccapurple; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-decoration: none;
}
.upperNavContact > a {
  /* border: solid red; */
  width: 100%;
  font-size: 18px;
  color: white;
  text-decoration: none;
}
.menuTrack {
  /* border: solid yellow; */
  width: 10%;
  height: 90%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.menuTrack > a {
  text-decoration: none;
}
.menuTrack > a > h3 {
  font-family: sans-serif;
  font-size: 17px;
  color: var(--customColor1);
  text-align: center;
}
.upperNavContent {
  /* border: solid red; */
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.upperNavContent > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.upperNavContent > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.upperNavContent > ul > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.respUpperNavContainer {
  /* border: solid red; */
  height: 90%;
  min-width: 100px;
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.respUpperNavContainer > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.respUpperMenuIcon {
  font-size: 25px;
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .upperNavContainer {
    /* border: solid greenyellow; */
    height: 7vh;
    width: 100%;
    background-color: var(--customColor1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  .upperNavContainer > ul {
    display: none;
  }
  .upperNavContent {
    display: none;
  }
  .menuTrack {
    /* border: solid; */
    width: 110px;
  }
  .respUpperNavContainer {
    display: flex;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .upperNavContainer {
    /* border: solid greenyellow; */
    height: 8vh;
    width: 100%;
    background-color: var(--customColor1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .upperNavContainer > ul {
    display: none;
  }
  .upperNavContent {
    display: none;
  }
  .menuTrack {
    width: 20%;
  }
  .respUpperNavContainer {
    display: flex;
  }
}
