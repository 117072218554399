.hrdContentContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.hrdContentContainer > h2 {
  font-size: 30px;
  color: var(--customColor2);
}
.hrdContentContainer > p {
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hrdContentContainer > h2 {
    font-size: 30px;
    text-align: center;
    color: var(--customColor2);
  }
  .hrdContentContainer > p {
    font-size: 20px;
    text-align: justify;
  }
}
