.meaProcedureContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.meaProcedureContainer > h2 {
  color: var(--customColor2);
}
.meaProcedureContainer > p {
  /* border: solid blue; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  text-align: justify;
}
.meaProcedureContainer > p > h3 {
  color: var(--customColor1);
  font-size: 20px;
}
.meaProcedureContainer > p > p {
  font-size: 20px;
}
.meaProcedureContainer > p > ul > li {
  font-size: 20px;
  margin: 10px;
  margin-left: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .meaProcedureContainer {
    /* border: solid red; */
    padding: 20px;
  }
}
