.meaBloreCertContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
}
.meaBloreCertWrapper {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
}
.meaBloreCertContent1 {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.meaBloreCertContent1 > img {
  width: 50%;
  height: 50%;
  border-radius: 10px;
}
.meaBloreCertContent1 > h2 {
  font-size: 25px;
  color: var(--customColor2);
  text-align: center;
  width: 100%;
}
.meaBloreCertContent1 > p {
  font-size: 20px;
  text-align: justify;
}
.meaBloreCertContent1 > ul > li {
  font-size: 20px;
  margin: 10px;
  margin-left: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .meaBloreCertContainer {
    /* border: solid red; */
    padding: 0px;
  }
  .meaBloreCertWrapper {
    /* border: solid green; */
    flex-direction: column-reverse;
    padding: 20px;
  }
  .meaBloreCertContent1 > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
