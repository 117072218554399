.hrdBloreContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.hrdBloreContainer > h1 {
  color: var(--customColor2);
  text-align: center;
}
.hrdBloreContainer > h1 > span {
  color: var(--customColor1);
}
.hrdBloreContainer > p {
  font-size: 20px;
  text-align: justify;
}
