.HomeFlagsContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.HomeFlagHead {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.HomeFlagHead > h2 {
  font-size: 30px;
  color: var(--customColor2);
}
.flagUnderline {
  border: 2px solid var(--customColor1);
  width: 40%;
}
.homeFlagMainContainer {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.homeFlagCardContainer {
  /* border: solid; */
  width: 20%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 20px;
}
.homeFlagCardContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(0.8);
}
.homeFlagCardCover {
  /* border: solid green; */
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 0.379)
  );
}
.homeFlagCardName {
  /* border: solid red; */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  z-index: 2;
  width: 100%;
  height: 40px;
  bottom: -20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px black;
  color: var(--customColor1);
  text-align: center;
}
.homeFlagCardName > h3 > a {
  color: var(--customColor1);
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeFlagMainContainer {
    /* border: solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
  .homeFlagCardContainer {
    /* border: solid; */
    min-width: 300px;
    margin: 40px;
  }
}
@media screen and (min-width: 780px) and (max-width: 990px) {
  .homeFlagMainContainer {
    /* border: solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
  }
  .homeFlagCardContainer {
    /* border: solid; */
    min-width: 300px;
    margin: 40px;
  }
}
