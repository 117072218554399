.marqueeContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
  gap: 10px;
}
.marqueeHead {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.marqueeUnderline {
  border: 2px solid var(--customColor1);
  width: 10%;
}
.marqueeHead > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
}
.marqueeCard {
  /* border: solid blue; */
  height: 300px;
  margin: 20px;
  position: relative;
  overflow: hidden;
}
.marqueeCard > a {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.marqueeCard > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.marqueeCover {
  /* border: solid blue; */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.448);
  transition: 200ms ease-in-out;
  transform: translateY(300px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.marqueeCover > h3 {
  color: white;
  font-size: 40px;
  color: white;
}
.marqueeCard:hover .marqueeCover {
  transform: translateY(0px);
}
