.birthImpDocContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.birthImpDocContainer > h2 {
  text-align: center;
  color: var(--customColor2);
  font-size: 30px;
}
.birthImpDocContainer > h2 > span {
  color: var(--customColor1);
}
.birthImpDocContainer > p {
  font-size: 20px;
  text-align: justify;
}
.marriageImpDocContent {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.marriageImpDocContent > ul {
  /* border: solid green; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
}
.marriageImpDocContent > ul > li {
  font-size: 20px;
  color: var(--customColor1);
}
.marriageImpDocContent > ul > li > span {
  color: var(--customColor2);
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .birthImpDocContainer {
    padding: 20px;
  }
  .marriageImpDocContent > ul {
    /* border: solid green; */
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}
