.birthCertProcedureContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.birthCertProcedureContainer > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
}
.birthCertProcedureContainer > h2 > span {
  color: var(--customColor1);
}

.birthCertProcedureContainer > p {
  font-size: 20px;
  text-align: justify;
}

.birthCertProcedureContainer > ol > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 30px;
}
.birthCertProcedureContainer > ol > li > span {
  color: var(--customColor2);
  font-weight: bold;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .birthCertProcedureContainer {
    padding: 20px;
  }
}
