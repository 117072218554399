.birthTypeWriterContainer {
  /* border: solid red; */
  width: 50%;
  position: absolute;
  top: 175px;
  left: 10px;
  padding: 20px;
}
.birthTypeWriterContainer > h2 > span {
  font-size: 40px;
  color: white;
}
.birthTypeWriterContainer > h2 {
  font-size: 40px;
  color: var(--customColor1);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .birthTypeWriterContainer {
    /* border: solid red; */
    width: 80%;top: 40px;

  }
  .birthTypeWriterContainer > h2 > span {
    font-size: 30px;
    color: white;
  }
  .birthTypeWriterContainer > h2 {
    font-size: 30px;
    color: var(--customColor1);
  }
}
