.meaBloreContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.meaBloreContainer > h1 {
  color: var(--customColor2);
  text-align: center;
}
.meaBloreContainer > h1 > span {
  color: var(--customColor1);
}
.meaBloreContainer > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width:320px) and (max-width:768px) {
  .meaBloreContainer {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
}