.whyHrdContainer {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
  }
  .whyHrdContainer > h2 > span {
    color: var(--customColor1);
  }
  .whyHrdContainer > h2 {
    text-align: center;
    color: var(--customColor2);
    font-size: 30px;
  }
  .whyHrdContainer > p {
    text-align: justify;
    font-size: 20px;
  }
  .whyHrdContainer > ul > li {
    list-style: none;
    font-size: 20px;
    text-align: justify;
    margin: 20px;
  }
  .whyHrdContainer > ul > li > h3 {
    color: var(--customColor2);
    font-weight: bold;
    font-size: 20px;
  }
  .whyHrdContainer > ul > li > span {
    color: var(--customColor2);
    font-weight: bold;
    font-size: 20px;
  }
  .whyHrdContainer > ul > li > h3 > span {
    color: var(--customColor1);
    font-weight: bold;
    font-size: 20px;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .whyHrdContainer {
      /* border: solid red; */
      padding: 20px;
    }
  }
  