.wesDocContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.wesDocContainer > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
  width: 100%;
}
.wesDocContainer > h2 > span {
  color: var(--customColor1);
}
.wesDocContainer > ul > li {
  list-style: none;
  font-size: 20px;
  margin: 20px;
  color: var(--customColor2);
}
.wesDocContainer > p {
  font-size: 20px;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .wesDocContainer {
    /* border: solid red; */
    padding: 20px;
  }
}
