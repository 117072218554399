.aboutUsContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.aboutUsHeadContainer {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutUsHeadContainer > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
}
.aboutUsHeadContainer > h2 > span {
  color: var(--customColor1);
}
.aboutUsWrapper {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aboutUsContent {
  /* border: solid brown; */
  width: 50%;
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.aboutUsContent > img {
  width: 100%;
  height: 100%;
}
.aboutUsMainContainer {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.aboutUsMainContainer > p {
  font-size: 20px;
  text-align: justify;
}

.aboutUsIcons {
  /* border: solid black; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}
.aboutUsImgContent {
  /* border: solid red; */
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.aboutUsImgContent > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutUsImgContent > p {
  text-align: center;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .aboutUsContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }
  .aboutUsWrapper {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .aboutUsContent {
    /* border: solid brown; */
    width: 100%;
    padding: 0px;
    height: 100%;
    margin: 10px;
    flex-direction: column;
  }
  .aboutUsIcons {
    /* border: solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  .aboutUsImgContent {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
  .aboutUsImgContent > img {
    width: 30%;
    height: 30%;
    object-fit: cover;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .aboutUsIcons {
    /* border: solid black; */
    flex-direction: row;
    gap: 50px;
  }
  .aboutUsImgContent {
    /* border: solid red; */
    width: fit-content;
    flex-direction: column;
    gap: 20px;
  }
  .aboutUsImgContent > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}
