.bahrainAttestationPage {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background-color: white;
}
