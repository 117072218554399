* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
  --customColor1: #ff7901;
  --customColor2: #009bff;
  --customBlack: #424652;
  --customWhite: #ececec;
}
@font-face {
  font-family: "customFont";
  src: url("./Fonts/futura/FUTURA55REGULAR.TTF") format("truetype");
}
h1,
h2,
h3,
h4,
li,
p,
label,
input {
  font-family: "customFont", sans-serif;
}
