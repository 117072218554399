.hrdDocsContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.hrdDocsContainer > h2 {
  font-size: 30px;
  color: var(--customColor2);
  text-align: center;
  width: 100%;
}
.hrdDocsContainer > h2 > span {
  color: var(--customColor1);
}
.hrdDocsContainer > p {
  text-align: justify;
  font-size: 20px;
  text-align: center;
  width: 100%;
}
.hrdDocsContainer > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
}
.hrdDocsContainer > ul > li > span {
  color: var(--customColor2);
  font-weight: bold;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .hrdDocsContainer {
    /* border: solid red; */
    padding: 20px;
  }
}
